*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #555;
  color: #fff;
  height: 60px;
  font-size: 2rem;
}

.user-list ul{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.user-list ul li{
  list-style: none;
}

.single-user {
  display: flex; 
  align-items: center;
  min-width: 400px;
  background-color: #333;
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
  color: #fff;
  font-size: 1rem;
}

.single-user img{
  margin-right: 20px;
  border-radius: 50%;
  width: 100px;
}

.user-info .user-mail {
  margin: 10px 0;
}

.user-input{
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: darkgray;
}

.user-input input{
  border-radius: 5px;
  width: 200px;
}
.user-input button{
  border-radius: 5px;
  width: 200px;
}